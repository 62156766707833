import React from 'react'

const Social = () => {
    return (
        <div className="home__social">
            <a href="https://is.linkedin.com/in/bjarni-g-einarsson-4b289830a" className="home__social-icon" target="_blank">
            <i className='bx bxl-linkedin' ></i>
            </a>

            <a href="https://authors.repec.org/pro/pei43/" className="home__social-repec" target="_blank">
                <span className="repec-text">RePEc</span>
            </a>

            <a href="mailto: bjarni.geir.einarsson@gmail.com" className="home__social-icon" target="_blank">
            <i className='bx bx-envelope' ></i>
            </a>
        </div>
    )
}

export default Social
