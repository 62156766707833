import React from 'react';
import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './components/home/home';
import Research from './components/research/Research'
import Resume from './components/resume/Resume'


const App = () => {
    return (
        <>
            <Header />

            <main className='main'>
                <Home />
                <Research />
                <Resume />
            </main>
            <Footer />

        </>
    )
}
export default App;