import React from 'react'

const Data = () => {
    return (
        <div className='home__data'>
            <h1 className="home__title">Bjarni Geir Einarsson</h1>
            <h3 className='home__subtitle'>Economist</h3>
            <p className='home__description'>I am an Economist at the Economics and Monetary Policy department of the Central Bank of Iceland and Adjunct Lecturer at the Department of Economics at the University of Iceland. <br /> My research interests include time series econometrics, macroeconometrics, macroeconomics, and monetary policy. <br /><br />I obtained my PhD from Universitat Pompeu Fabra in 2023.
            </p>

            <a href="mailto:bjarni.geir.einarsson@gmail.com" className="button button--flex"><span>Contact me  </span>
                <i className='bx bxs-paper-plane'></i></a>
        </div>
    )
}


export default Data