import React from 'react';
import resumePDF from '../../assets/BGE_CV.pdf';  // Import the PDF
import './Resume.css';

const Resume = () => {
    return (
        <section className="resume section" id="resume">
            <h2 className="section__title">Curriculum Vitae</h2>
            <div className="resume__container">
                <iframe 
                    src={resumePDF} 
                    title="BGE Resume" 
                    type="application/pdf" 
                    className="resume__iframe" 
                    aria-label="Embedded PDF of Curriculum Vitae"
                />
                <p className="resume__fallback">
                    If you can't view the PDF, you can 
                    <a href={resumePDF} download="BGE_CV.pdf"> download it here</a>.
                </p>
            </div>
        </section>
    );
};

export default Resume;
