import React from 'react';
import "./Research.css";

const Research = () => {

    const papers = [
        { 
            title: "Testing Optimal Monetary Policy in a Currency Union", 
            subtitle: "Central Bank of Iceland Working Paper no. 96", 
            year: 2024, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP96_vefur.pdf" 
        },
        { 
            title: "Online Monitoring of Policy Optimality", 
            subtitle: "Central Bank of Iceland Working Paper no. 95", 
            year: 2024, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WorkingPaper_95_BjarniGEinarsson_OnlineMonetoringOfPolicyOptimalityApril2024_.pdf" 
        },
        { 
            title: "Small open economies in the vast ocean of global high finance",
            subtitle: "joint with Kristófer Gunnlaugsson, Thorvardur Tjörvi Ólafsson, and Thórarinn G. Pétursson, Central Bank of Iceland Working Paper no. 73",
            year: 2016, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/wp.73_161121.pdf" 
        },
        { 
            title: "The long history of financial boom-bust cycles in Iceland – Part II: Financial cycles ", 
            subtitle: "joint with Kristófer Gunnlaugsson, Thorvardur Tjörvi Ólafsson, and Thórarinn G. Pétursson, Central Bank of Iceland Working Paper no. 72", 
            year: 2016, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP_72.pdf" 
        },
        { 
            title: "QMM: A quarterly macroeconomic model of the Icelandic economy – Version 3.0", 
            subtitle: "joint with Ásgeir Daníelsson, Magnús F. Gudmundsson, Svava J. Haraldsdóttir, Thórarinn G. Pétursson, Signý Sigmundardóttir, Jósef Sigurdsson, and Rósa B. Sveinsdóttir, Central Bank of Iceland Working Paper no. 71", 
            year: 2015, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP_71_net_nytt.pdf" 
        },
        { 
            title: "The Ins and Outs of Icelandic Unemployment", 
            subtitle: "Central Bank of Iceland Working Paper no. 69", 
            year: 2015, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP69.pdf" 
        },
        { 
            title: "The long history of financial boom-bust cycles in Iceland – Part I: Financial crises", 
            subtitle: "joint with Kristófer Gunnlaugsson, Thorvardur Tjörvi Ólafsson, and Thórarinn G. Pétursson, Central Bank of Iceland Working Paper no. 68", 
            year: 2015, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP68.pdf" 
        },
        { 
            title: "A Dynamic Factor Model for Icelandic Core Inflation", 
            subtitle: "Central Bank of Iceland Working Paper no. 67", 
            year: 2014, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP%2067%20net.pdf" 
        },
        { 
            title: "How Natural is the Natural Rate? Unemployment Hysteresis in Iceland", 
            subtitle: "joint with Jósef Sigurdsson, Central Bank of Iceland Working Paper no. 64", 
            year: 2013, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP%20No%2064.pdf" 
        },
        { 
            title: "On our own? The Icelandic business cycle in an international context", 
            subtitle: "joint with Gudjón Emilsson, Svava J. Haraldsdóttir, Thórarinn G. Pétursson and Rósa B. Sveinsdóttir, Central Bank of Iceland Working Paper no. 63", 
            year: 2013, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP63.pdf" 
        },
        { 
            title: "The production and export structure of the Icelandic economy: An international comparison", 
            subtitle: "joint with Gudjón Emilsson, Svava Jóhanna Haraldsdóttir, Ólafur Ö. Klemensson, Thórarinn G. Pétursson and Rósa B. Sveinsdóttir, Central Bank of Iceland Working Paper no. 60", 
            year: 2013, 
            link: "https://www.cb.is/library/Skraarsafn---EN/Working-Papers/WP60_net.pdf" 
        }
      
    ]
    return (
        <section className='research section' id="research">
            <h2 className="section__title">Research</h2>
            <span className='section__subtitle'>Working Papers</span>
            <div className='research__container container grid'>
                <ul className='research__list'>
                    {papers.map((paper, index) => (
                        <li key={index} className="research__item">
                            <a href={paper.link} target="_blank" className="research__link">
                                <h3 className="research__title">{paper.title}</h3>
                                <p className="research__subtitle">{paper.subtitle}</p>
                                <span className="research__year">{paper.year}</span> {/* Display year */}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>

        </section>
    )
}

export default Research