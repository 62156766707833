import React, { useState, useEffect } from 'react';
import "./Header.css";

const Header = () => {
    /*======================= TOGGLE MENU ==============================*/
    const [Toggle, showMenu] = useState(false);

    /*======================= ACTIVE LINK ==============================*/
    const [activeLink, setActiveLink] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section'); 
            let currentSection = '';
            
            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;
                if (window.scrollY >= sectionTop - sectionHeight / 3) {
                    currentSection = section.getAttribute('id');
                }
            });
            
            setActiveLink(currentSection); 
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className='header'>
            <nav className="nav container">
                <a href="index.html" className="nav_logo">B G E</a>

                <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                    <ul className='nav__list grid'>
                        <li className="nav__item">
                            <a href="#home"
                                className={`nav__link ${activeLink === 'home' ? 'active-link' : ''}`}>
                                <i className='bx bx-home-alt nav__icon'></i> home
                            </a>
                        </li>

                        <li className="nav__item">
                            <a href="#research"
                                className={`nav__link ${activeLink === 'research' ? 'active-link' : ''}`}>
                                <i className='bx bx-book nav__icon'></i>research
                            </a>
                        </li>

                        <li className="nav__item">
                            <a href="#resume"
                                className={`nav__link ${activeLink === 'resume' ? 'active-link' : ''}`}>
                                <i className='bx bx-file-blank nav__icon'></i> cv
                            </a>
                        </li>
                    </ul>
                    <i className="fa-solid fa-xmark nav__close" onClick={() => showMenu(!Toggle)}></i>
                </div>
                <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                    <i className='bx bx-menu' ></i>
                </div>
            </nav>
        </header>
    );
};

export default Header;
