import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container">
                <p className="footer__text">© 2024 BGE. All rights reserved.</p>

                <p className="footer__credit">
                    Created by <a href="https://github.com/expaetra" target="_blank" rel="noopener noreferrer">PI</a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
